<template>
  <div class="underline mb-3">
    <div class="mb-3">
      <div class="text-black">
        {{ $t('booking_review.booked_credits') }}
      </div>
      <b-collapse id="booked-collapse" visible>
        <div v-for="(tc, i) in bookedTimeSlots" :key="i" class="d-flex align-items-center my-2">
          <div class="circle-text mr-3">{{ i + 1 }}</div>
          <div class="d-flex justify-content-between flex-grow-1">
            <span>{{ tc.start_datetime | formatDate(dateformat) }}</span>
            <span
              >{{ tc.start_datetime | formatDate('H:mm') }} -
              {{ tc.end_datetime | formatDate('H:mm') }}</span
            >
          </div>
        </div>
        <div class="d-flex" v-if="hasUnBookedSlot">
          <div class="pill-text mr-3">{{ notBookedLabel }}</div>
          <div class="d-flex flex-column">
            <span class="text-black">{{ $t('booking_review.not_booked_yet') }}</span>
            <small>{{ $t('booking_review.not_booked_yet_desc') }}</small>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import { removeDuplicateTimeSlot } from '../utils'
const BookingStore = createNamespacedHelpers('booking')

@Component({
  computed: {
    ...BookingStore.mapState(['multiPackage', 'bookingReview']),
    ...BookingStore.mapGetters(['calendarChangedMaid']),
  },
})
export default class BookedCredits extends Vue {
  get recurrentList() {
    return this.multiPackage.recurrentList
  }

  get dateformat() {
    return this.$i18n.locale === 'th' ? 'ddd DD MMM YYYY' : 'ddd MMM DD, YYYY'
  }

  get bookedTimeSlots() {
    let baseTimeslots = [...this.calendarChangedMaid]
    const newTimeslots = removeDuplicateTimeSlot(
      this.bookingReview.calendar,
      this.multiPackage.historyRecurrentItems
    )
    // return [
    //   ...this.calendarChangedMaid,
    //   ...this.multiPackage.historyRecurrentItems,
    //   ...this.bookingReview.calendar
    // ]
    // return [...baseTimeslots, ...this.bookingReview.calendar]
    return this.recurrentList.filter(
      ({ start_datetime, isAvailable }) => !!start_datetime && isAvailable
    )
  }

  get lastNumber() {
    const bookedTimeCount = this.bookedTimeSlots.length
    // return bookedTimeCount > 0
    //   ? parseInt(this.bookedTimeSlots[this.bookedTimeSlots.length - 1].number)
    //   : 0
    return bookedTimeCount > 0 ? bookedTimeCount : 0
  }

  get hasUnBookedSlot() {
    return this.lastNumber !== this.multiPackage.package.value
  }

  get notBookedLabel() {
    const lastNumber = this.lastNumber
    if (!this.hasUnBookedSlot) return ``
    if (lastNumber + 1 === this.multiPackage.package.value) return lastNumber + 1
    return `${lastNumber + 1} - ${this.multiPackage.package.value}`
  }
}
</script>
